import React, { useMemo } from 'react'
import { Field, FieldProps, FieldRenderProps, FormSpy } from 'react-final-form'
import TimeField from 'components/ui/TimeField'
import isString from 'lodash/isString'
import isDate from 'lodash/isDate'

type TimeFieldProps = {
  label?: string
  description?: string
  placeholder?: string
}

export type Props = TimeFieldProps & {
  name: string
  baseDate: Date | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerField({
  input,
  label,
  description,
  baseDate,
  meta: { touched, error: syncError, submitError, submitting }
}: FieldRenderProps<Date, HTMLInputElement> & TimeFieldProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  const timeFieldProps = {
    disabled: submitting,
    error: showError ? error : null,
    description,
    label,
    ...input
  }
  const now = useMemo(() => new Date(), [])

  if (isString(baseDate)) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const baseDateValue: Date = values[baseDate] || now
          if (!baseDateValue || !isDate(baseDateValue)) {
            throw new Error(
              `No base date under '${baseDate}' key: ${baseDateValue}`
            )
          }
          return <TimeField baseDate={baseDateValue} {...timeFieldProps} />
        }}
      </FormSpy>
    )
  }

  return <TimeField baseDate={baseDate} {...timeFieldProps} />
}

export default function FormTimeField({
  name,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<Date, FieldRenderProps<Date, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => <InnerField {...props} {...innerProps} name={name} />}
      {...fieldProps}
    />
  )
}
