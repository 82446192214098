import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import useDeepCompareEffect from 'hooks/useDeepCompareEffect'

export type Props = {
  name: string
  label?: string
  description?: string
  placeholder?: string
  error?: string | null
  value: Date | null
  baseDate: Date
  fullWidth?: boolean
  onChange: (value: Date | null) => void
  disabled?: boolean
}

const getTimeString = (date: Date | null) => {
  if (date) {
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${hours}:${minutes}`
  }
  return ''
}

export default function TimeField({
  label,
  name,
  description,
  placeholder,
  error,
  baseDate,
  disabled,
  fullWidth,
  value,
  onChange,
  ...inputProps
}: Props) {
  const [innerValue, setInnerValue] = useState<string>(getTimeString(value))

  const onChangeRef = React.useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useDeepCompareEffect(() => {
    if (innerValue) {
      const [hours, minutes] = innerValue.split(':').map(Number)
      const newDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate(),
        hours,
        minutes,
        0
      )
      if (Number.isNaN(newDate.getTime())) {
        onChangeRef.current(null)
        return
      }
      onChangeRef.current(newDate)
    } else {
      onChangeRef.current(null)
    }
  }, [innerValue, baseDate])

  useDeepCompareEffect(() => {
    setInnerValue(getTimeString(value))
  }, [value])

  return (
    <div className={cn('relative', { 'w-full': fullWidth })}>
      {label && (
        <label className="block font-medium text-gray-700" htmlFor={name}>
          {label}
        </label>
      )}
      {description && (
        <div>
          <p className="text-sm italic text-gray-500 font-light mt-1">
            {description}
          </p>
        </div>
      )}
      <div className="relative">
        <input
          className={cn(
            'w-full h-[42px] mt-1 border border-solid border-gray-300 p-2 outline-none-mouse focus:ring-orange-500 focus:border-orange-500 shadow-sm rounded-md scroll-offset',
            {
              'text-gray-500': disabled,
              'ring-red-500 border-red-500': error
            }
            // inputProps.className
          )}
          onChange={(e) => setInnerValue(e.target.value)}
          placeholder={placeholder}
          type="time"
          value={innerValue ?? ''}
          {...inputProps}
        />
        {error && (
          <div className="absolute inset-y-0 right-6 md:right-8 top-1 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-500"
            />
          </div>
        )}
      </div>
      {error && (
        <div>
          <p className="text-sm italic text-red-500 font-light mt-1">{error}</p>
        </div>
      )}
    </div>
  )
}
