/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import AddressField from 'components/ui/AddressField'

type TextFieldProps = {
  label?: string
  description?: string
  placeholder?: string
}

export type Props = TextFieldProps & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerField({
  input,
  label,
  description,
  meta: { touched, error: syncError, submitError, submitting }
}: FieldRenderProps<string, HTMLInputElement> & TextFieldProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <AddressField
      description={description}
      disabled={submitting}
      error={showError ? error : null}
      label={label}
      {...input}
    />
  )
}

export default function FormAddressField({
  name,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<string, FieldRenderProps<string, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => <InnerField {...props} {...innerProps} name={name} />}
      {...fieldProps}
    />
  )
}
