import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import DateField from 'components/ui/DateField'

type DateFieldProps = {
  label?: string
  description?: string
  placeholder?: string
}

export type Props = DateFieldProps & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerField({
  input,
  label,
  description,
  meta: { touched, error: syncError, submitError, submitting }
}: FieldRenderProps<Date, HTMLInputElement> & DateFieldProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <DateField
      description={description}
      disabled={submitting}
      error={showError ? error : null}
      label={label}
      {...input}
    />
  )
}

export default function FormDateField({
  name,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<Date, FieldRenderProps<Date, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => <InnerField {...props} {...innerProps} name={name} />}
      {...fieldProps}
    />
  )
}
