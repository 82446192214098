import Script from 'next/script'

const HTML = `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    var Tawk_API_Callbacks = Tawk_API_Callbacks || [];
    var executeTawkCallbacks = function() {
      while (Tawk_API_Callbacks.length > 0) {
        try {
          Tawk_API_Callbacks.shift()()
        } catch (e) {
          console.error(e)
        }
      }
    };
    Tawk_API.onLoad = Tawk_API.onLoad || executeTawkCallbacks;
    Tawk_API.onBeforeLoad = Tawk_API.onBeforeLoad || executeTawkCallbacks;
    Tawk_API.onChatMaximized = Tawk_API.onChatMaximized || executeTawkCallbacks;
    
    (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/653ebe11a84dd54dc48679e9/1hduhctos';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
    })();
`

export default function ChatWidget() {
  return (
    <Script
      dangerouslySetInnerHTML={{ __html: HTML }}
      id="chat-widget"
      strategy="lazyOnload"
    />
  )
}
