/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import TextField from '../TextField'

export type Props = {
  name: string
  label?: string
  description?: string
  placeholder?: string
  error?: string | null
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}

const loadMapsScript = (callbackName: string) => {
  return new Promise<void | Event>((resolve, reject) => {
    if (typeof window === 'undefined') {
      return
    }
    const mapScript = document.getElementById('google-maps-script')
    // eslint-disable-next-line
    if ((window as any).google?.maps) {
      resolve()
    } else if (mapScript) {
      mapScript.onload = (...args) => {
        if (mapScript.onload) {
          mapScript.onload(...args)
        }
        resolve()
      }
    } else {
      const mapsScript = document.createElement('script')
      mapsScript.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDBXkdl95KlS2SmNfQfHYQRGMic6USqegw&callback=${callbackName}`
      mapsScript.id = 'google-maps-script'
      mapsScript.async = true
      mapsScript.onerror = reject
      mapsScript.onload = resolve
      document.body.appendChild(mapsScript)
    }
  })
}

export default function AddressField({
  label,
  description,
  placeholder,
  error,
  value,
  onChange,
  name,
  disabled
}: Props) {
  const [text, setText] = useState<string>(value)
  const [loaded, setLoaded] = useState<boolean>(false)

  const scriptUuid = useMemo(() => `cb${uuidv4().replace(/-/g, '')}`, [])

  useEffect(() => {
    setText(value)
  }, [value])

  useEffect(() => {
    loadMapsScript(scriptUuid).then(() => setLoaded(true))
  }, [scriptUuid])

  const onSelect = (address: string) => {
    onChange(address)
  }

  const onBlur = () => {
    setText(value)
  }
  const searchOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  }

  return (
    <PlacesAutocomplete
      googleCallbackName={
        typeof window === 'undefined' ? '__iNeverRun' : scriptUuid
      }
      onChange={setText}
      onSelect={onSelect}
      searchOptions={searchOptions}
      shouldFetchSuggestions={loaded}
      value={text}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className="relative">
          <TextField
            description={description}
            error={error}
            label={label}
            {...getInputProps({
              placeholder,
              onBlur
            })}
            disabled={disabled}
            name={name}
          >
            <div className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              {suggestions.map((suggestion, index) => {
                const className = cn('py-1', {
                  'bg-orange-100 text-gray-900': suggestion.active
                })
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                    key={`${suggestion.id}-${index}`}
                    aria-orientation="vertical"
                  >
                    <p className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-100 hover:text-gray-900 cursor-pointer">
                      {suggestion.description}
                    </p>
                  </div>
                )
              })}
            </div>
          </TextField>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
