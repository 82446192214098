import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TextFieldFake, {
  Props as TextFieldProps
} from 'components/ui/TextFieldFake'

export type Props = {
  name: string
  label?: string
  description?: string
  placeholder?: string
  error?: string | null
  value: Date | null
  onChange: (value: Date) => void
  disabled?: boolean
}

export default function DateField({
  name,
  label,
  description,
  placeholder,
  error,
  value,
  disabled,
  onChange
}: Props) {
  const DateInput = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (dateProps, ref: React.Ref<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { className, children, ...props } = dateProps || {}
      return (
        <TextFieldFake
          ref={ref}
          description={description}
          error={error}
          label={label}
          placeholder={placeholder}
          {...props}
          name={name}
        />
      )
    }
  )
  return (
    <DatePicker
      customInput={<DateInput name="" onClick={() => null} value="" />}
      disabled={disabled}
      onChange={(date: Date) => onChange(date)}
      popperModifiers={
        {
          name: 'preventOverflow',
          options: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } as any
      }
      selected={value}
    />
  )
}
